import { reactive } from "vue";

export const store = reactive({
  userId: undefined,
  user: {
    address: undefined,
    username: undefined,
    email: undefined,
    photoURL: undefined,
    displayName: undefined,
  },
  // user owner nfts
  nfts: undefined,
  setNfts(newNfts) {
    this.nfts = [...newNfts];
  },
  // tab explorer nfts
  feed: undefined,
  setFeed(newFeed) {
    this.feed = [...newFeed];
  },
  isAuthenticated() {
    var userId = localStorage.getItem("userId");
    this.userId = userId;
    return !!userId;
  },
  logout() {
    localStorage.removeItem("userId");
    localStorage.removeItem("user");
    this.userId = undefined;
  },
  setUser(user) {
    this.user = { ...user };
    localStorage.setItem("user", JSON.stringify(user));
    return this.user;
  },
  getUser() {
    var userData = JSON.parse(localStorage.getItem("user"));
    this.user = { ...userData };
    return this.user;
  },
});
