
import { defineComponent } from "vue";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
} from "@ionic/vue";
import { useRouter } from "vue-router";

import firebase from "firebase/compat/app";
import * as firebaseui from "firebaseui";
import "firebaseui/dist/firebaseui.css";

import { getAuth } from "firebase/auth";

export default defineComponent({
  name: "LogInPage",
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonPage,
  },
  setup() {
    const router = useRouter();
    return { router };
  },
  data() {
    return {
      isLoggedIn: localStorage.getItem("isUserLoggedIn")
        ? JSON.parse(localStorage.getItem("isUserLoggedIn") || "")
        : undefined,
      loggedUserId: localStorage.getItem("userUuid"),
    };
  },
  watch: {
    isLoggedIn(val) {
      console.log("watch", val);
      this.isLoggedIn = val;
      if (val) {
        this.router.push({ name: "TabExplorePage" });
      }
    },
  },
  mounted() {
    const isLoggedInHandle = (authResult) => {
      const { user } = authResult;
      localStorage.setItem("userId", user.uid);

      if (authResult.additionalUserInfo.isNewUser) {
        console.log("is new user");
        this.router.push({
          name: "LogInSetupPage",
          params: {
            email: user.email,
            photoURL: user.photoURL,
            displayName: user.displayName,
          },
        });
      } else {
        this.router.push({ name: "TabExplorePage" });
      }

      return false;
    };

    // Initialize the FirebaseUI Widget using Firebase.
    const auth = getAuth();

    var ui = new firebaseui.auth.AuthUI(auth);

    var uiConfig = {
      callbacks: {
        /* eslint-disable no-unused-vars */
        signInSuccessWithAuthResult: function (
          authResult: any,
          redirectUrl: string
        ) {
          // User successfully signed in.
          // Return type determines whether we continue the redirect automatically
          // or whether we leave that to developer to handle.

          // update state with UI loged
          // setLoggedUser(authResult.user.uid);
          // authResult.additionalUserInfo.isNewUser
          console.log({ authResult });

          isLoggedInHandle(authResult);

          return false;
        },
        uiShown: function () {
          // The widget is rendered.
          // Hide the loader.
          const el = document.getElementById("loader");
          if (el) {
            el.style.display = "none";
          }
        },
      },
      // Will use popup for IDP Providers sign-in flow instead of the default, redirect.
      signInFlow: "popup",
      // signInSuccessUrl: '<url-to-redirect-to-on-success>',
      signInOptions: [
        // Leave the lines as is for the providers you want to offer your users.
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
        // firebase.auth.TwitterAuthProvider.PROVIDER_ID,
        firebase.auth.EmailAuthProvider.PROVIDER_ID,
        // firebase.auth.PhoneAuthProvider.PROVIDER_ID,
      ],
      // Terms of service url.
      tosUrl: "<your-tos-url>",
      // Privacy policy url.
      privacyPolicyUrl: "<your-privacy-policy-url>",
    };

    ui.start("#firebaseui-auth-container", uiConfig);
  },
});
