import { createRouter, createWebHistory } from "@ionic/vue-router";
import { RouteRecordRaw } from "vue-router";
import TabsPage from "../views/TabsPage.vue";
import LogInPage from "../views/LogInPage.vue";
import IndexPage from "../views/IndexPage.vue";
import { store } from "../lib/store.js";

import lodash from "lodash";
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Index",
    component: IndexPage,
  },
  {
    path: "/login",
    name: "Login",
    component: LogInPage,
    props: (route) => ({ ...route.params }),
  },
  {
    path: "/setup",
    name: "LogInSetupPage",
    component: () => import("@/views/LogInSetupPage.vue"),
  },
  {
    path: "/tabs/",
    component: TabsPage,
    name: "TabsPage",
    children: [
      {
        path: "",
        redirect: "/tabs/explore",
      },
      {
        path: "web3",
        name: "TabWeb3Page",
        component: () => import("@/views/TabWeb3Page.vue"),
      },
      {
        path: "profile",
        name: "TabProfilePage",
        component: () => import("@/views/TabProfilePage.vue"),
      },
      {
        path: "explore",
        name: "TabExplorePage",
        component: () => import("@/views/TabExplorePage.vue"),
      },
    ],
  },
  {
    path: "/image/:id",
    name: "ImageView",
    component: () => import("@/views/ImageView.vue"),
    props: (route) => ({ ...route.params }),
  },
  {
    path: "/settings",
    name: "SettingsPage",
    component: () => import("@/views/SettingsPage.vue"),
    props: (route) => ({ ...route.params }),
  },
  {
    path: "/nft/:nftId",
    name: "NftView",
    component: () => import("@/views/NftView.vue"),
    props: (route) => ({ ...route.params }),
  },
  {
    path: "/user/:address",
    name: "UserView",
    component: () => import("@/views/UserView.vue"),
    props: (route) => ({ ...route.params }),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  console.log(to.name, "isAuthenticated", store.isAuthenticated());
  // make sure the user is authenticated
  // ❗️ Avoid an infinite redirect
  // if (!store.isAuthenticated() && to.name !== "Login") {
  //   // redirect the user to the login page
  //   return next({ name: "Login" });
  // }

  console.log("address", store.user);
  if (store.isAuthenticated()) {
    if (lodash.isEmpty(store.user)) {
      console.log("Login");
      return next({ name: "Login" });
    } else {
      if (to.name === "Login") {
        console.log("TabExplorePage");
        return next({ name: "TabExplorePage" });
      }
    }
    // else if (!store.user.address) {
    //   console.log("LogInSetupPage");
    //   return { name: "LogInSetupPage" };
    // }
  }

  return next();
});

export default router;
