// input: fileName 123456.jpeg
// output: 123456
export const getFilePropsFromName = (fileName: string) => {
  const file = fileName.split(".")[0];
  const format = fileName.split(".")[1];
  return { file, format };
};

export const firebaseConfig: object = {
  apiKey: process.env.VUE_APP_FIREBASE_apiKey,
  authDomain: process.env.VUE_APP_FIREBASE_authDomain,
  projectId: process.env.VUE_APP_FIREBASE_projectId,
  storageBucket: process.env.VUE_APP_FIREBASE_storageBucket,
  messagingSenderId: process.env.VUE_APP_FIREBASE_messagingSenderId,
  appId: process.env.VUE_APP_FIREBASE_appId,
  measurementId: process.env.VUE_APP_FIREBASE_measurementId,
};

export const getUserAvatarURL = (address: string) => {
  return `https://api.multiavatar.com/${address}.svg`;
};
