import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

import { IonicVue } from "@ionic/vue";

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";

/* Theme variables */
import "./theme/variables.css";

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { firebaseConfig } from "./lib/shared";
import { store } from "./lib/store.js";

import { defineCustomElements } from "@ionic/pwa-elements/loader";
defineCustomElements(window);

const app = createApp(App).use(IonicVue).use(router);

const fireapp = initializeApp(firebaseConfig);
const analytics = getAnalytics(fireapp);
app.config.globalProperties.$firebaseapp = fireapp;
app.config.globalProperties.$analytics = analytics;
app.config.globalProperties.$store = store;

router.isReady().then(() => {
  app.mount("#app");
});
