<template>
  <!-- <head>
    <meta charset="UTF-8" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <link rel="icon" type="image/png" href="assets/img/favicon.png" />
    <link
      href="https://unpkg.com/boxicons@2.0.7/css/boxicons.min.css"
      rel="stylesheet"
    />
    <link rel="stylesheet" href="assets/css/styles.css" />
    <title>Pippe – Free Landing page for your mobile app</title>
  </head> -->
  <ion-page>
    <ion-content>
      <div class="relative overflow-hidden xl:h-screen bg-special">
        <!-- Start Hero -->
        <div class="container mx-auto sm:h-96 xl:h-full">
          <div class="absolute top-8">
            <div class="flex items-center">
              <img src="/img/starbase-logo-light.png" alt="" class="h-20" />
              <span class="text-white font-semibold mx-2 text-xl"
                >NFT maker</span
              >
            </div>
          </div>
          <div
            class="grid content-center mt-32 xl:h-full gap-y-8 sm:gap-y-0 sm:gap-x-10 sm:grid-cols-2 xl:mt-auto"
          >
            <div class="space-y-5 sm:col-span-1 md:space-y-8">
              <h1
                class="text-4xl font-semibold sm:text-5xl xl:text-7xl bg-clip-text text-transparent bg-gradient-to-r from-indigo-400 via-pink-500 to-purple-500"
              >
                Easily mint your very own NFT right from your phone
              </h1>
              <p class="max-w-sm text-lg md:text-2xl text-white">
                With our user-friendly interface and simple steps, anyone can
                create their own unique NFT in just a few taps.
              </p>
              <div class="flex justify-start space-x-4">
                <a
                  href="/login"
                  class="text-3xl font-semibold ease-in-out transform shadow hover:-translate-y-1 hover:shadow-lg border border-purple-600 font-montserrat py-2 px-8 rounded-xl bg-purple-600 text-white transition-all duration-300"
                  >Go to app</a
                >
              </div>
            </div>
            <div class="grid justify-items-center">
              <img
                class="bottom-0 w-2/3 sm:absolute sm:-right-32 md:-right-16 lg:right-0 sm:w-2/4 lg:w-2/5"
                src="assets/img/hand-illustration.png"
                alt=""
              />
            </div>
          </div>
        </div>
        <div
          class="hidden sm:block sm:absolute bottom-6 sm:inset-x-1/2 text-white"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="w-6 h-6 animate-bounce"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M19 14l-7 7m0 0l-7-7m7 7V3"
            />
          </svg>
        </div>
      </div>
      <!-- End Hero -->

      <section class="container px-5 py-12 mx-auto lg:py-28">
        <!-- Start About App -->
        <div class="grid gap-4 md:gap-10 lg:grid-cols-3">
          <div class="">
            <h2 class="text-2xl font-semibold md:text-3xl">About Our App</h2>
          </div>
          <div class="lg:col-span-2">
            <p class="text-lg md:text-2xl">
              Whether you're an artist looking to showcase your work, a
              collector wanting to add to your portfolio, or just someone who
              wants to try out this exciting new technology, our app makes it
              easy for you to get started.
            </p>
          </div>
        </div>
      </section>

      <div class="container mx-auto border-b border-gray-200 sm:px-4"></div>

      <section class="container px-5 py-12 mx-auto lg:py-28 bg-indigo-50">
        <!-- Start About App -->
        <div class="grid gap-4 md:gap-10 lg:grid-cols-3">
          <div class="">
            <h2 class="text-2xl font-semibold md:text-3xl">
              How does it work?
            </h2>
          </div>
          <div class="lg:col-span-2">
            <p class="text-lg md:text-2xl">
              First, you'll need to choose the image that you want to turn into
              an NFT. This can be a piece of original artwork, a favorite photo.
              Next, you'll be able to customize your NFT with a title,
              description, and other metadata. Once you're satisfied with your
              creation, you can easily mint it using the app and have it added
              to the blockchain.
            </p>
          </div>
        </div>
      </section>
      <!-- End About App -->

      <div class="container mx-auto border-b border-gray-200 sm:px-4"></div>

      <section class="container px-5 py-12 mx-auto lg:py-28">
        <!-- Start Features -->
        <div class="grid gap-10 xl:grid-cols-3">
          <div class="">
            <h2 class="text-2xl font-semibold md:text-3xl">Features</h2>
          </div>
          <div class="grid xl:col-span-2 gap-y-8 sm:gap-10 sm:grid-cols-2">
            <div class="space-y-5">
              <div class="flex items-center space-x-3">
                <div
                  class="flex items-center justify-center w-12 h-12 text-white bg-purple-500 rounded-lg"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-6 h-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10"
                    />
                  </svg>
                </div>
                <h3 class="text-lg font-semibold">Free minting</h3>
              </div>
              <p class="max-w-md text-base text-gray-600 lg:text-xl">
                We pay for the gas fees! Still you will have the ownership of
                your NFT
              </p>
            </div>
            <div class="space-y-5">
              <div class="flex items-center space-x-3">
                <div
                  class="flex items-center justify-center w-12 h-12 text-white bg-purple-500 rounded-lg"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-6 h-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                    />
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                  </svg>
                </div>
                <h3 class="text-lg font-semibold">Explore</h3>
              </div>
              <p class="max-w-md text-base text-gray-600 lg:text-xl">
                Browse and discover other users' NFTs on the app.
              </p>
            </div>
            <div class="space-y-5">
              <div class="flex items-center space-x-3">
                <div
                  class="flex items-center justify-center w-12 h-12 text-white bg-purple-500 rounded-lg"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-6 h-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
                    />
                  </svg>
                </div>
                <h3 class="text-lg font-semibold">Security</h3>
              </div>
              <p class="max-w-md text-base text-gray-600 lg:text-xl">
                You keep the ownership! Our Smart contract mints the NFT
                directly to your web3 wallet.
              </p>
            </div>
            <div class="space-y-5">
              <div class="flex items-center space-x-3">
                <div
                  class="flex items-center justify-center w-12 h-12 text-white bg-purple-500 rounded-lg"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-6 h-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M13 10V3L4 14h7v7l9-11h-7z"
                    />
                  </svg>
                </div>
                <h3 class="text-lg font-semibold">Fast</h3>
              </div>
              <p class="max-w-md text-base text-gray-600 lg:text-xl">
                All NFTs are deployed on polygon mainnet, thus with the fastest
                minting process
              </p>
            </div>
          </div>
        </div>
      </section>
      <!-- End Features -->

      <section class="container mx-auto md:px-5">
        <!-- Start Download App -->
        <div
          class="px-5 pt-12 space-y-8 text-center lg:pt-24 md:px-0 bg-gray-900 md:space-y-12"
        >
          <div class="space-y-4">
            <h2
              class="text-2xl font-semibold md:text-3xl bg-clip-text text-transparent bg-gradient-to-r from-indigo-400 via-pink-500 to-purple-500"
            >
              Start minting now
            </h2>
            <p class="text-base text-white">
              Mint your creativity using the app and have it added to the
              blockchain.
            </p>
          </div>
          <div class="flex justify-center space-x-4">
            <a
              href="/login"
              class="text-3xl font-semibold ease-in-out transform shadow hover:-translate-y-1 hover:shadow-lg border border-purple-600 font-montserrat py-2 px-8 rounded-xl bg-purple-600 text-white transition-all duration-300"
              >Go to app
            </a>
          </div>
          <div class="grid justify-items-center">
            <img
              class="w-11/12 sm:w-2/3 md:w-1/3"
              src="assets/img/iphone-mockup.svg"
              alt=""
            />
          </div>
        </div>
      </section>
      <!-- End Download App -->

      <footer class="container px-5 mx-auto my-8">
        <!-- Start Footer -->
        <div
          class="space-y-3 text-xs text-center text-gray-600 md:flex md:justify-between md:items-center md:space-y-0"
        >
          <div
            class="space-y-2 md:space-y-0 md:flex md:justify-between md:items-center md:space-x-5"
          >
            <p>Copyright © 2023 Starbase PTE LTD. All rights reserved.</p>
          </div>
          <div class="flex justify-center space-x-4">
            <a href="">
              <i
                class="text-2xl transition duration-500 ease-in-out transform hover:scale-125 bx bxl-facebook-circle"
              ></i>
            </a>
            <a href="">
              <i
                class="text-2xl transition duration-500 ease-in-out transform hover:scale-125 bx bxl-twitter"
              ></i>
            </a>
          </div>
        </div>
      </footer>
      <!-- End Footer -->
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent, ref } from "vue";

import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonGrid,
  IonRow,
  IonCard,
  IonButtons,
  loadingController,
  IonRefresher,
  IonRefresherContent,
  IonBackButton,
} from "@ionic/vue";
import { useRoute } from "vue-router";
import { get, orderBy } from "lodash";

export default defineComponent({
  name: "IndexPage",
  components: {
    IonPage,
    IonContent,
  },
  async mounted() {
    console.log("mounted index");
  },
});
</script>
